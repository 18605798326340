
import { format } from 'date-fns'
import { enUS, fr, es } from 'date-fns/locale'

const locales: any = {enUS, fr, es}

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
export const dateFormat = (date: Date, formatStr = 'PP', lang = "en") => {

  return format(date, formatStr, {
    // @ts-ignore
    locale: locales[lang] // or global.__localeId__
  })
}
